import { createGlobalStyle } from 'styled-components';
import '@fontsource/poppins';
import px2vw from '~/utils/px2vw';
const GlobalStyle = createGlobalStyle`  
  * {
    font-family: 'Poppins';
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  /* :root {
      font-size: ${px2vw(20)};

      @media (min-width: 768px) {
        font-size: ${px2vw(18)};
      }

      @media (min-width: 1024px) {
        font-size: ${px2vw(18)};
      }
    } */

  *:focus {
    outline: 0;
  }

  html,
  body,
  #root {
    height: 100%;
    background: #E7E9F6;
    font-family: 'Poppins';
  }
  
  body,
  input,
  button, a {
    font: 14px 'Poppins';
    font-weight: 600;
    font-family: 'Poppins';
  }

  * {
    -webkit-font-smoothing: antialiased;
  }

  *::-webkit-scrollbar {
    width: 0.3em;
  }
   
  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  }
   
  *::-webkit-scrollbar-thumb {
    background-color: rgba(47, 74, 125, 1);
    outline: 0.5px solid transparent;
  }
`;

export default GlobalStyle;
