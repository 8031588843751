import React, { useState, useEffect, useRef } from 'react';

import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPageContainer, FullPagePanel } from '@components/fullpage_fs';
import { Management, TimeValuable, Budget, HomePlatform, Plans, Technologies } from './section';
import Address from './section/address';
import { isMobile } from 'react-device-detect';

const Platform = () => {
  const windowHeight = useRef(window.innerHeight);
  const [viewState, setViewState] = useState({
    currentPanel: 1,
    transitioning: false,
    currentTop: 0,
  });

  const onSetSection = (sectionNumber) => {
    setViewState((prev) => {
      setTimeout(() => {
        setViewState((prev) => ({ ...prev, transitioning: false }));
      }, 1000);
      return {
        transitioning: true,
        currentPanel: sectionNumber,
        currentTop: -windowHeight.current * (sectionNumber - 1),
      };
    });
  };

  const sections = {
    1: <HomePlatform />,
    2: <Management />,
    3: <Budget />,
    4: <Technologies />,
    // 5: <Plans />,
    6: <TimeValuable />,
    7: !isMobile ? <Address onSetSection={onSetSection} /> : <></>,
  };

  return (
    <>
      <FullPageContainer
        showIndicators={true}
        viewState={viewState}
        onSetSection={onSetSection}
        setViewState={setViewState}
        windowHeight={windowHeight}
      >
        {Object.entries(sections).map(([key, Component]) => (
          <div key={key}>{Component}</div>
        ))}
      </FullPageContainer>
    </>
  );
};

export default Platform;
