// eslint-disable-next-line import-helpers/order-imports
import { isMobile } from 'react-device-detect';
import { Grid, Typography, Link } from '@mui/material';

import { FullPagePanel } from '@components/fullpage_fs';

import bg_black from '@assets/bg_blue.svg';
import bg_blue from '@assets/bg_img_blue.png';
import imgParceiroAbwf1 from '@assets/parceiro_abwf.svg';
import imgParceiroAbwf2 from '@assets/parceiro_abwf2.svg';
import imgParceiroEscola from '@assets/parceiro_escola_mundo.svg';
import imgParceiroNw from '@assets/parceiro_nowos.svg';
import imgParceiroStructo from '@assets/parceiro_structo.svg';
import imgParceiroCasaCorreas from '@assets/casa_das_correias.png';
import imgParceiroExpert from '@assets/expert_solutions.png';
import imgParceiroMaru from '@assets/maru.png';

import px2vw from '~/utils/px2vw';

// import logo from '@assets/logo.svg';

const Partners = () => (
  <FullPagePanel bgColor='white' background={`url(${bg_blue})`}>
    <Grid
      container
      direction='row'
      spacing={isMobile ? 5 : 10}
      sx={{
        paddingX: isMobile ? '5%' : '10%',
      }}
    >
      <Grid item xs={12} bgcolor={'transparent'}>
        <Typography
          variant='h1'
          textAlign={'left'}
          color={'white'}
          fontFamily={'Montserrat'}
          fontSize={isMobile ? px2vw(40) : px2vw(50)}
          fontWeight={800}
          // paddingBottom={10}
        >
          PARCERIAS
        </Typography>
      </Grid>
      <Grid item xs={3} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <img src={imgParceiroEscola} alt='Descrição do SVG imgParceiroEscola' width={isMobile ? '90%' : '40%'} />
      </Grid>
      <Grid item xs={3} bgcolor={'transparent'} display={'flex'} justifyContent={'left'} alignItems={'center'}>
        <img src={imgParceiroNw} alt='Descrição do SVG imgParceiroNw' width={isMobile ? '90%' : '70%'} />
      </Grid>
      <Grid item xs={3} bgcolor={'transparent'} display={'flex'} justifyContent={'left'} alignItems={'center'}>
        <img src={imgParceiroStructo} alt='Descrição do SVG imgParceiroStructo' width={isMobile ? '100%' : '75%'} />
      </Grid>
      <Grid item xs={3} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <img src={imgParceiroAbwf1} alt='Descrição do SVG imgParceiroAbwf1' width={isMobile ? '90%' : '75%'} />
      </Grid>
      <Grid item xs={4} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <img src={imgParceiroCasaCorreas} alt='Descrição do SVG imgParceiroAbwf1' width={isMobile ? '90%' : '65%'} />
      </Grid>
      <Grid item xs={4} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <img src={imgParceiroExpert} alt='Descrição do SVG imgParceiroAbwf1' width={isMobile ? '70%' : '30%'} />
      </Grid>
      <Grid item xs={4} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <img src={imgParceiroMaru} alt='Descrição do SVG imgParceiroAbwf1' width={isMobile ? '70%' : '30%'} />
      </Grid>
      {/* <Grid
        item
        xs={isMobile ? 12 : 6}
        bgcolor={'transparent'}
        display={'flex'}
        justifyContent={isMobile ? 'center' : 'right'}
        alignItems={'center'}
        paddingTop={px2vw(40)}
      >
        <img src={imgParceiroAbwf2} alt='Descrição do SVG imgParceiroAbwf2' width={isMobile ? '55%' : '45%'} />
      </Grid>
      <Grid
        item
        xs={isMobile ? 12 : 6}
        bgcolor={'transparent'}
        display={'flex'}
        justifyContent={isMobile ? 'center' : 'left'}
        alignItems={'center'}
        paddingTop={px2vw(40)}
      >
        <Grid
          container
          width={isMobile ? '82%' : '65%'}
          spacing={3}
          paddingLeft={isMobile ? 0 : 4}
          display={'flex'}
          justifyContent={isMobile ? 'center' : 'left'}
        >
          <Grid item display={'flex'} alignItems={'center'}>
            <img src={imgParceiroAbwf1} alt='Descrição do SVG imgParceiroAbwf1' width={'240vh'} />
          </Grid>
          <Grid item>
            <Typography
              variant='caption'
              color='white'
              fontSize={17}
              fontWeight={600}
              fontFamily={'Poppins'}
              component='p'
              textAlign={isMobile ? 'center' : 'left'}
            >
              A PROFRAME Engenharia e Tecnologia é membro da ABWF associação brasileira do woodframe.
              {!isMobile && <br />}
              <Link
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.abwf.com.br'
                underline='none'
                color={'#0066FF'}
              >
                www.abwf.com.br
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  </FullPagePanel>
);

export default Partners;
