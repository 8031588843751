import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { isMobile, isTablet } from 'react-device-detect';
import { Element } from 'react-scroll';

import { Grid, Typography } from '@mui/material';

import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';

import bg_blue from '@assets/bg_img_blue.png';
import bg_white from '@assets/bg_white.svg';
import { ReactComponent as ImgGaleryServiceHouse } from '@assets/galery_our_service03.svg';
import img_galery_serv2 from '@assets/galery_our_service03.svg';
import { ReactComponent as ImgGaleryServiceBuilding } from '@assets/galery_our_services.svg';
import img_galery_serv1 from '@assets/galery_our_services.svg';
import { ReactComponent as ImgObraComercial } from '@assets/img_obra_comercial.svg';
import { ReactComponent as ImgObraGeral } from '@assets/img_obra_geral.svg';
import { ReactComponent as ImgObraIndustrial } from '@assets/img_obra_industrial.svg';
import { ReactComponent as ImgObraResidencial } from '@assets/img_obra_residencial.svg';

import px2vw from '~/utils/px2vw';
// import logo from '@assets/logo.svg';
const servicesOurs = [
  {
    img: <ImgObraResidencial width={'100%'} height={'100%'} />,
    txt: (
      <>
        <span style={{ fontWeight: 600, fontFamily: 'Poppins' }}>Obras Residenciais de Alto Padrão:</span> Construções
        residenciais exclusivas e sofisticadas. Elevando o padrão em cada detalhe.
      </>
    ),
  },
  {
    img: <ImgObraComercial width={'100%'} height={'100%'} />,
    txt: (
      <>
        <strong style={{ fontWeight: 800 }}>Obras Comerciais:</strong> Construções comerciais inovadoras e funcionais.
        Transformamos espaços para impulsionar negócios.
      </>
    ),
  },
  {
    img: <ImgObraIndustrial width={'100%'} height={'100%'} />,
    txt: (
      <>
        <strong style={{ fontWeight: 800 }}>Obras Industriais:</strong> Obras industriais de alta performance. Soluções
        robustas para impulsionar a eficiência operacional.
      </>
    ),
  },
  {
    img: <ImgObraGeral width={'100%'} height={'100%'} />,
    txt: (
      <>
        <strong style={{ fontWeight: 800 }}>Reformas em geral:</strong> Reformas versáteis para transformar ambientes.
        Soluções personalizadas para atender às suas necessidades.
      </>
    ),
  },
];
const Services = () => (
  <>
    <FullPagePanel bgColor='white' background={`url(${bg_white})`}>
      <Grid
        container
        direction='row'
        spacing={2}
        sx={{
          paddingX: isMobile ? '5%' : '10%',
        }}
      >
        <Grid item xs={12}>
          <Typography
            // component='h5'
            // className='wrapper'
            variant='h1'
            textAlign={'left'}
            color={'#1F3867'}
            fontFamily={'Montserrat'}
            fontSize={isMobile ? px2vw(38) : px2vw(50)}
            fontWeight={800}
          >
            NOSSOS SERVIÇOS
          </Typography>
        </Grid>
        {!isMobile ? (
          <>
            <Grid item xs={6} bgcolor={'transparent'} display={'flex'} justifyContent={'left'} alignItems={'center'}>
              {/* <ImgGaleryServiceBuilding width={'90%'} /> */}
              <img src={img_galery_serv1} alt='Descrição do SVG1' width={'90%'} />
            </Grid>
            <Grid item xs={6} bgcolor={'transparent'}>
              <Typography
                variant='caption'
                color='#1F3867'
                fontSize={'3vh'}
                fontWeight={500}
                fontFamily={'Poppins'}
                component='span'
                textAlign={'left'}
              >
                <ul>
                  {servicesOurs.map((service, index) => {
                    return <li key={index}>{service.txt}</li>;
                  })}
                </ul>
              </Typography>
            </Grid>
          </>
        ) : (
          servicesOurs.map((service, index) => {
            return (
              <>
                <Grid item xs={6} bgcolor={'transparent'}>
                  {service.img}
                </Grid>
                <Grid item xs={6} bgcolor={'transparent'}>
                  <Typography
                    variant='caption'
                    color='#1F3867'
                    fontSize={px2vw(14)}
                    fontWeight={500}
                    fontFamily={'Poppins'}
                    component='span'
                    textAlign={'left'}
                    // width={'80%'}
                  >
                    {service.txt}
                  </Typography>
                </Grid>
              </>
            );
          })
        )}
      </Grid>
    </FullPagePanel>
  </>
);

export default Services;
