import React, { useRef, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import { ToastContainer } from 'react-toastify';

// Styles
import './App.css';
import '@fontsource/poppins'; // Defaults to weight 400 with all styles included.
import GlobalStyle from './styles/global-styles';
import { defaultTheme } from './styles/default-theme';

// Pages
import Home from '@pages/home'; // Adjust the path as per your project structure
import Platform from '@pages/platform'; // Adjust the path as per your project structure
import FloatingButton from './components/FloatingButton';
import { HeaderMenu } from './components/header_footer/HeaderMenu';
import 'react-toastify/dist/ReactToastify.min.css';
// import Address from '@pages/platform/section/address';
function App() {
  const windowHeight = useRef(window.innerHeight);
  const [viewState, setViewState] = useState({
    currentPanel: 1,
    transitioning: false,
    currentTop: 0,
  });
  const onSetSection = (sectionNumber) => {
    setViewState((prev) => {
      setTimeout(() => {
        setViewState((prev) => ({ ...prev, transitioning: false }));
      }, 1000);
      return {
        transitioning: true,
        currentPanel: sectionNumber,
        currentTop: -windowHeight.current * (sectionNumber - 1),
      };
    });
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <HeaderMenu onSetSection={onSetSection} />
        <FloatingButton />
        <Routes>
          <Route
            path='/'
            element={
              <Home
                viewState={viewState}
                onSetSection={onSetSection}
                setViewState={setViewState}
                windowHeight={windowHeight}
              />
            }
          />
          <Route path='/platform' element={<Platform />} />
        </Routes>
      </ThemeProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
