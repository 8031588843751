import React, { useState, useEffect, useRef } from 'react';
// import './App.css';

import { isMobile } from 'react-device-detect';

import { Box, Grid, Typography } from '@mui/material';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';

import bg_white from '@assets/bg_white.svg';
import imgBudget from '@assets/images/ScreenBudgets.svg';
import img5 from '@assets/img5.svg';
import img6 from '@assets/img6.svg';
import img7 from '@assets/img7.svg';
import img8 from '@assets/img8.svg';

import px2vw from '~/utils/px2vw';

const Budget = () => {
  return (
    <FullPagePanel bgColor='transparent' background={`url(${bg_white})`}>
      <Grid container>
        <Grid
          item
          xs={isMobile ? 12 : 6}
          display={'flex'}
          flexDirection={'column'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          justifyContent={'center'}
          padding={isMobile ? '10%' : '5%'}
          // bgcolor={'red'}
        >
          <Typography
            fontFamily={'Montserrat'}
            fontSize={isMobile ? px2vw(30) : px2vw(50)}
            fontWeight={800}
            paddingBottom={5}
            color={'#1F3867'}
            textAlign={'left'}
          >
            ORÇAMENTO
          </Typography>
          <img
            src={imgBudget}
            alt='Simple'
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : 6}
          direction={'column'}
          padding={2}
          // bgcolor={'red'}
          display={'flex'}
          alignItems={isMobile ? 'center' : 'start'}
          justifyContent={'center'}
        >
          <Box
            bgcolor={'transparent'}
            // width={isMobile ? '85%' : '90%'}
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'space-between'}
          >
            <img src={img5} alt='Simple' width={'40%'} style={{ marginLeft: isMobile ? '5%' : 0 }} />
            <img src={img6} alt='Simple' width={'40%'} style={{ marginLeft: isMobile ? '5%' : 0 }} />
            <img src={img7} alt='Simple' width={'40%'} style={{ marginLeft: isMobile ? '5%' : 0 }} />
            <img src={img8} alt='Simple' width={'40%'} style={{ marginLeft: isMobile ? '5%' : 0 }} />
          </Box>
          {!isMobile && (
            <Box width={'90%'} paddingTop={3}>
              <Typography
                variant='caption'
                color='#1F3867'
                fontSize={'2.8vh'}
                fontWeight={500}
                fontFamily={'Poppins'}
                component='span'
                textAlign={'left'}
                width={'80%'}
              >
                <ul>
                  Otimize o processo de orçamento com nossa plataforma especializada, oferecendo funcionalidades
                  abrangentes para garantir precisão e eficiência em cada detalhe:
                  <li>
                    <strong>Estruturas Orçamentárias:</strong> Visão organizada dos custos em cada fase do projeto
                  </li>
                  <li>
                    <strong>Valores de Custo e Venda:</strong>Transparência nos custos e lucros, facilitando decisões.
                  </li>
                  <li>
                    <strong>Gerenciamento de Insumos:</strong> Controle eficaz do uso de materiais, evitando
                    desperdícios.
                  </li>
                  <li>
                    <strong>Curva ABC:</strong> Priorização inteligente para otimizar recursos e reduzir riscos.
                  </li>
                </ul>
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </FullPagePanel>
  );
};

export default Budget;
