import { createTheme } from '@mui/material/styles';
import '@fontsource/poppins';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#1F3867',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F3950D',
      contrastText: '#fff',
    },
    info: {
      main: '#4167B0',
      contrastText: '#fff',
    },
    success: {
      main: '#27AE60',
      contrastText: '#fff',
    },
    warning: {
      main: '#FFC924',
      contrastText: '#fff',
    },
    error: {
      main: '#EF6354',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});
