import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { FormControlLabel, FormGroup } from '@mui/material';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 120,
  height: 26,
  padding: 0,
  margin: 0,
  border: '2px solid #4B6BA9',
  borderRadius: 6,
  fontFamily: 'Poppins',
  fontSize: 12,
  fontWeight: 600,
  '& .MuiSwitch-switchBase': {
    margin: 0,
    padding: 0,
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(100%)',
      '& .MuiSwitch-thumb:before': {
        content: '"Anual"',
        justfyContent: 'center',
        padding: 2,
        paddingLeft: 8,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'white',
        //content: '"Mensal"',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    width: 60,
    height: 24,
    borderRadius: 1,
    // boxShadow: 'none',
    padding: 0,
    '&:before': {
      content: '"Mensal"',
      position: 'absolute',
      padding: 2,
      paddingLeft: 8,
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'white',
    // borderRadius: 1,
    padding: 2,
    alignItems: 'center',
    '&:before': {
      content: '"Mensal"',
      color: theme.palette.primary.main,
      position: 'absolute',
      alignItems: 'center',
      padding: 2,
      paddingLeft: 8,
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    },
    '&:after': {
      content: '"Anual"',
      position: 'absolute',
      right: 0,
      width: '50%', // Metade do track
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
    '&.MuiFormControlLabel-root': {
      marginRight: 100,
    },
  },
}));

interface Props {
  setIsAnualPlan?: (value: boolean) => void;
}

const ToggleSwitch: React.FC<Props> = (props) => {
  const { setIsAnualPlan } = props;

  const [checked, setChecked] = useState({ anual: false });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ anual: event.target.checked });
    console.log(event.target.checked);
    setIsAnualPlan(event.target.checked);
  };
  return (
    <FormGroup>
      <FormControlLabel
        control={<CustomSwitch sx={{ m: 0 }} checked={checked.anual} onChange={handleChange} />}
        label=''
        sx={{ mr: 0 }}
      />
    </FormGroup>
  );
};

export default ToggleSwitch;
