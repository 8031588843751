import * as Yup from 'yup';

const DEFAULT_ERROR_MESSAGE = 'Campo obrigatório não preenchido.';

export const ContactSchema = Yup.object()
  .shape({
    name: Yup.string().required(DEFAULT_ERROR_MESSAGE),
    email: Yup.string().required('Informe um e-mail válido.'),
    assunto: Yup.string().required(DEFAULT_ERROR_MESSAGE),
    mensage: Yup.string().required(DEFAULT_ERROR_MESSAGE),
  })
  .required();
