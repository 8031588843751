import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { Element } from 'react-scroll';

import { Grid, Typography } from '@mui/material';

import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';

import bg_blue from '@assets/bg_img_blue.png';
import bg_white from '@assets/bg_white.svg';
import img_galery_serv2 from '@assets/galery_our_service03.svg';
import { ReactComponent as ImgObraComplet } from '@assets/img_obra_complet2.svg';
import { ReactComponent as ImgObraCosult } from '@assets/img_obra_consult2.svg';
import { ReactComponent as ImgObraIndustri } from '@assets/img_obra_industri2.svg';
import { ReactComponent as ImgObraResiBin } from '@assets/img_obra_resi_bim.svg';
import { isMobile } from 'react-device-detect';
import px2vw from '~/utils/px2vw';
// import logo from '@assets/logo.svg';
const servicesOurs2 = [
  {
    img: <ImgObraResiBin width={'100%'} height={'100%'} />,
    txt: (
      <>
        <strong style={{ fontWeight: 1000 }}>Projeto residenciais e comerciais em BIM:</strong> Projetos residenciais e
        comerciais avançados em BIM, garantindo eficiência e precisão em cada etapa.
      </>
    ),
  },
  {
    img: <ImgObraIndustri width={'100%'} height={'100%'} />,
    txt: (
      <>
        <strong>Projetos Industriais:</strong> Atendemos demandas específicas com projetos industriais, desenvolvendo
        espaços internos, áreas de escritório e galpões industriais.
      </>
    ),
  },
  {
    img: <ImgObraComplet width={'100%'} height={'100%'} />,
    txt: (
      <>
        <strong>Projetos complementares:</strong> Realizamos projetos estruturais, elétrico, hidráulico, AVCB e de
        climatização; todos em BIM.
      </>
    ),
  },
  {
    img: <ImgObraCosult width={'100%'} height={'100%'} />,
    txt: (
      <>
        <strong>Consultoria:</strong> Consultoria especializada em obras e projetos arquitetônicos, trazendo orientação
        precisa e soluções inovadoras.
      </>
    ),
  },
];
const Service2 = () => (
  <>
    <FullPagePanel bgColor='black' background={`url(${bg_blue})`}>
      <Grid
        container
        direction='row'
        rowSpacing={3}
        spacing={2}
        sx={{
          //   display: 'flex',
          //   alignItems: 'baseline',
          //   justifyContent: 'left',
          paddingX: isMobile ? '4%' : '10%',
        }}
      >
        {!isMobile ? (
          <>
            <Grid
              item
              xs={6}
              bgcolor={'transparent'}
              display={'flex'}
              justifyContent={'center'}
              alignContent={'flex-end'}
            >
              <Typography
                variant='caption'
                color='#fff'
                fontSize={'3vh'}
                fontWeight={500}
                fontFamily={'Poppins'}
                component='span'
                textAlign={'left'}
                // padding={10}
                // width={'80%'}
              >
                <ul>
                  {servicesOurs2.map((service, index) => {
                    return <li key={index}>{service.txt}</li>;
                  })}
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={6} bgcolor={'transparent'} display={'flex'} justifyContent={'left'} alignItems={'center'}>
              {/* <ImgGaleryServiceHouse width={'90%'} /> */}
              <img src={img_galery_serv2} alt='Descrição do SVG 2' width={'100%'} />
            </Grid>
          </>
        ) : (
          servicesOurs2.map((service, index) => {
            return (
              <>
                <Grid item xs={6} bgcolor={'transparent'}>
                  <Typography
                    variant='caption'
                    color='#fff'
                    fontSize={px2vw(14)}
                    fontWeight={500}
                    fontFamily={'Poppins'}
                    component='span'
                    textAlign={'left'}
                    // width={'80%'}
                  >
                    {service.txt}
                  </Typography>
                </Grid>
                <Grid item xs={6} bgcolor={'transparent'}>
                  {service.img}
                </Grid>
              </>
            );
          })
        )}
      </Grid>
    </FullPagePanel>
  </>
);

export default Service2;
