// eslint-disable-next-line import-helpers/order-imports
import { Button, Grid, Typography } from '@mui/material';
import { FullPagePanel } from '@components/fullpage_fs';

import Backgroundtwo from '@assets/images/Backgroundtwo.png';
import BuildMan from '@assets/images/BuildMan.png';
import Simple from '@assets/images/symplepratic.png';
import px2vw from '~/utils/px2vw';
import { isMobile } from 'react-device-detect';

const HomePlatform = () => {
  return (
    <FullPagePanel bgColor={'transparent'} background={`url(${Backgroundtwo})`}>
      <Grid
        // display={'flex'}
        // justifyContent={'space-between'}
        alignItems={'center'}
        container
        xs={12}
        md={12}
        paddingX={isMobile ? '3%' : '10%'}
      >
        <Grid
          item
          xs={isMobile ? 12 : 6}
          // md={6}
          padding={2}
          alignItems={'center'}
          justifyContent={'center'}
          display={'flex'}
          flexDirection={'column'}
        >
          <img
            src={Simple}
            alt='Simple'
            style={{
              width: '90%',
            }}
          />
          <div
            style={{
              width: '90%',
              height: '100%',
              // marginTop: '20px',
              marginBottom: '20px',
            }}
          >
            <Typography
              sx={{
                color: '#fff',
                fontSize: isMobile ? px2vw(18) : px2vw(20),
                fontFamily: 'Poppins',
                fontWeight: 100,
                textAlign: 'left',
                lineHeight: isMobile ? '24px' : '33.2px',
              }}
            >
              Construa seus projetos conosco! Oferecemos soluções personalizadas, qualidade excepcional e prazos
              confiáveis.
            </Typography>
          </div>
          <div
            style={{
              width: '90%',
            }}
          >
            <Button
              sx={{
                backgroundColor: '#ff8c00',
                textTransform: 'none',
                fontSize: isMobile ? px2vw(14) : px2vw(18),
                fontWeight: 600,
                color: '#fff',
                width: px2vw(150),
                '&:hover': {
                  backgroundColor: '#ff8c00',
                },
              }}
              // onClick={() => window.open('http://www.proframe.com.br:3000/register')}
            >
              Em Breve
            </Button>
          </div>
        </Grid>
        <Grid item xs={isMobile ? 12 : 6} display={'flex'} alignContent={'center'} justifyContent={'center'}>
          <img
            src={BuildMan}
            alt=''
            style={{
              width: '80%',
            }}
          />
        </Grid>
      </Grid>
    </FullPagePanel>
  );
};

export default HomePlatform;
