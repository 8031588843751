import React, { useEffect, useState } from 'react';
import './index.css'; // Importando o CSS para estilização
import icon_whatsapp from '@assets/icon_whatsapp.svg';

const FloatingButton = () => {
  const [pulsing, setPulsing] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Ativa o pulsar
      setPulsing(true);

      // Desativa o pulsar após 2s, que é a duração da animação
      setTimeout(() => setPulsing(false), 5000);
    }, 20000); // Ativa a animação a cada 20 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  }, []);

  return (
    <button
      type='button'
      className={`floating-button ${pulsing ? 'pulsing' : ''}`}
      onClick={() => window.open('https://wa.me/message/QYMYNKLTSLQKK1', '_blank')}
      style={{
        backgroundImage: `url(${icon_whatsapp})`,
        backgroundRepeat: 'no-repeat',
        // backgroundSize: '50%', // Ajuste conforme necessário para caber bem
        backgroundPosition: 'center', // Centraliza o ícone no botão
        backgroundColor: 'transparent', // Mantém o fundo transparente
        border: 'none', // Remove a borda padrão do botão
      }}
    />
  );
};

export default FloatingButton;
