import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Grid, Typography } from '@mui/material';

// import { yupResolver } from '@hookform/resolvers/yup';

import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';
import { Notify } from '@components/Notify';

import bg_blue from '@assets/bg_img_blue.png';
import bg_white from '@assets/bg_white.svg';
import imgPersonContact from '@assets/contact_vector.svg';

import { FormInputText } from '~/components/FormInputText';
import px2vw from '~/utils/px2vw';

import { ContactSchema } from './schema';

// import logo from '@assets/logo.svg';

const Contact = () => {
  const [loading, setLoading] = React.useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(ContactSchema),
  });

  const sendFormContact = async (formData: any) => {
    try {
      setLoading(true);
      const requestData = {
        name: formData.name,
        email: formData.email,
        subject: formData.assunto,
        message: formData.mensage,
      };

      const response = await fetch('/api/v1/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Success:', data);
        Notify.success({ text: 'E-mail enviado com sucesso!', options: { autoClose: 5000 } });
        reset();
        setLoading(false);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error post:', error);
      Notify.error({ text: 'Tentando outra forma de envio!' });
      const uri = `mailto:comercial@proframe.com.br?subject=${encodeURIComponent(formData.assunto)}&body=${encodeURIComponent(formData.mensage)}`;
      window.location.href = uri;
      setLoading(false);
    }
  };

  return (
    <FullPagePanel bgColor='black' background={`url(${bg_white})`}>
      <Grid
        container
        direction='row'
        spacing={10}
        sx={{
          paddingX: isMobile ? '5%' : '10%',
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant='h1'
            textAlign={'left'}
            color={'#1F3867'}
            fontFamily={'Montserrat'}
            fontSize={isMobile ? px2vw(35) : px2vw(50)}
            fontWeight={800}
          >
            ENTRE EM CONTATO
          </Typography>
        </Grid>
        <Grid item xs={12} bgcolor={'transparent'}>
          <Grid
            noValidate
            component='form'
            onSubmit={handleSubmit(sendFormContact)}
            container
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={10}
            sx={{
              paddingX: isMobile ? '5%' : '10%',
            }}
          >
            <Grid
              item
              xs={isMobile ? 12 : 6}
              bgcolor={'transparent'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {/* <ImgGaleryServiceBuilding width={'90%'} /> */}
              <img src={imgPersonContact} alt='Descrição do imgPersonContact' width={'110%'} />
            </Grid>
            <Grid
              item
              xs={isMobile ? 12 : 6}
              direction={'column'}
              // bgcolor={'red'}
              display={'flex'}
              justifyItems={'left'}
              alignItems={'end'}
              // spacing={1}
            >
              <FormInputText
                control={control}
                required
                fullWidth
                id='name'
                placeholder='Digite seu nome completo'
                name='name'
                autoComplete='name'
                size='medium'
                error={!!errors.name}
                helperText={!!errors.name && errors.name.message}
              />
              <FormInputText
                control={control}
                required
                fullWidth
                id='email'
                placeholder='Digite seu email'
                name='email'
                autoComplete='email'
                size='medium'
                style={{ marginTop: 3 }}
                error={!!errors.email}
                helperText={!!errors.email && errors.email.message}
              />
              <FormInputText
                control={control}
                required
                fullWidth
                id='assunto'
                placeholder='Assunto a tratar'
                name='assunto'
                size='medium'
                style={{ marginTop: 3 }}
                error={!!errors.assunto}
                helperText={!!errors.assunto && errors.assunto.message}
              />
              <FormInputText
                control={control}
                required
                multiline
                fullWidth
                heightMult={'190px'}
                maxRows={8}
                id='mensage'
                placeholder='Digite aqui sua mensagem'
                name='mensage'
                size='medium'
                // style={{ paddingTop: 3 }}
                error={!!errors.mensage}
                helperText={!!errors.mensage && errors.mensage.message}
              />
              <LoadingButton
                loading={loading}
                variant='contained'
                loadingPosition='center'
                // color='primary'
                size='medium'
                type='submit'
                sx={{
                  width: 180,
                  marginTop: 1,
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 580,
                  fontFamily: 'poppins',
                  background: '#2F4A7D',
                  ':hover': { background: '#4167B0' },
                  height: '50px',
                  borderRadius: '8px',
                }}
              >
                Enviar E-mail
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullPagePanel>
  );
};

export default Contact;
