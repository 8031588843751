import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import './style.css';

export const Maps = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCWp0tpAVVX26B_XiSyDJ-I7xLoenHDgX8',
  });

  const proframePosition = {
    lat: -3.075627147565471,
    lng: -59.96828865773977,
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '8px' }}
          center={proframePosition}
          zoom={16}
        >
          <Marker
            position={proframePosition}
            label={{
              text: 'Proframe Engenharia',
              color: '#0000CD',
              fontSize: '16px',
              className: 'marker-label',
            }}
          />
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
};
