import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { Element } from 'react-scroll';
import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';

import bg_white from '@assets/bg_white.svg';
import { Grid, Typography } from '@mui/material';
// import logo from '@assets/logo.svg';
// import { ReactComponent as ImgClientsMQ } from '@assets/cliente_consult_empresarial.svg';
// import { ReactComponent as ImgClientsCreathus } from '@assets/cliente_creathus.svg';
// import { ReactComponent as ImgClientsConecthus } from '@assets/cliente_conecthus.svg';
// import { ReactComponent as ImgClientsFitOne } from '@assets/cliente_fitone.svg';
import imgClientsConecthus from '@assets/cliente_conecthus.svg';
import imgClientsFitOne from '@assets/cliente_fitone.svg';
import imgClientsMQ from '@assets/cliente_consult_empresarial.svg';
import imgClientsCreathus from '@assets/cliente_creathus.svg';
import imgClientsJabil from '@assets/jabil.png';
import { isMobile } from 'react-device-detect';
import px2vw from '~/utils/px2vw';

const Clients = () => {
  return (
    <FullPagePanel bgColor='white' background={`url(${bg_white})`}>
      <Grid
        container
        direction='row'
        sx={{
          paddingX: isMobile ? '5%' : '10%',
        }}
      >
        <Grid item xs={12} bgcolor={'transparent'}>
          <Typography
            // component='h5'
            // className='wrapper'
            variant='h1'
            textAlign={'left'}
            color={'#1F3867'}
            fontFamily={'Montserrat'}
            fontSize={isMobile ? px2vw(40) : px2vw(50)}
            fontWeight={800}
            paddingBottom={10}
          >
            CLIENTES
          </Typography>
        </Grid>
        <Grid item xs={4} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {/* <ImgGaleryServiceBuilding width={'90%'} /> */}
          <img
            src={imgClientsMQ}
            alt='Descrição do SVG 1'
            width={isMobile ? '100%' : '50%'}
            style={{ paddingBottom: 10 }}
          />
        </Grid>
        <Grid item xs={4} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {/* <ImgGaleryServiceBuilding width={'90%'} /> */}
          {/* <ImgClientsConecthus /> */}
          <img src={imgClientsConecthus} alt='Descrição do SVG 3' width={isMobile ? '90%' : '50%'} />
        </Grid>
        <Grid item xs={4} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {/* <ImgGaleryServiceBuilding width={'90%'} /> */}
          {/* <ImgClientsCreathus /> */}
          <img src={imgClientsCreathus} alt='Descrição do SVG 2' width={isMobile ? '100%' : '50%'} />
        </Grid>
        <Grid item xs={6} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <img
            src={imgClientsFitOne}
            alt='Descrição do SVG 4'
            width={isMobile ? '80%' : '35%'}
            style={{ marginTop: 20, marginLeft: isMobile ? 0 : 200 }}
          />
        </Grid>
        <Grid item xs={6} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <img
            src={imgClientsJabil}
            alt='Descrição do SVG 4'
            width={isMobile ? '80%' : '35%'}
            style={{ marginTop: 20, marginRight: isMobile ? 0 : 200 }}
          />
        </Grid>
      </Grid>
    </FullPagePanel>
  );
};

export default Clients;
