import { useNavigate } from 'react-router-dom';

import { Grid, Link, Typography } from '@mui/material';

import { FullPagePanel } from '@components/fullpage_fs';

import AddressImg from '@assets/AddressImg.svg';
import LineVertical from '@assets/LineVertical.svg';
import Mail from '@assets/Mail.svg';
import WhatsApp from '@assets/Whatsapp.svg';

import { Notify } from '~/components/Notify';

import { Maps } from './components/Maps';
const sections = [
  {
    index: 1,
    id: '/#home_1',
    text: 'Home',
  },
  {
    index: 2,
    id: '/#Services_2',
    text: 'Nossos Serviços',
  },
  {
    index: 4,
    id: 'platform',
    text: 'Nossa Plataforma',
  },
  {
    index: 4,
    id: '/#Clients_4',
    text: 'Clientes',
  },
  {
    index: 5,
    id: '/#Partners_5',
    text: 'Parceiros',
  },
  {
    index: 6,
    id: '/#WhoWeAre_6',
    text: 'Quem somos',
  },
  {
    index: 7,
    id: '/#Team_7',
    text: 'Nossa equipe',
  },
  {
    index: 8,
    id: '/#Contact_8',
    text: 'Contato',
  },
];
const Address = ({ onSetSection }) => {
  const navigate = useNavigate();
  const sendFormContact = () => {
    try {
      // const uri = 'mailto:comercial@proframe.com.br?subject=site&body=Contato Cliente site';
      navigate('/');
      setTimeout(() => (window.location.href = '/#Contact_8'), 1);
    } catch (err) {
      Notify.error({ text: err.message });
    }
  };
  return (
    <FullPagePanel background={`url(${AddressImg})`} bgColor='transparent'>
      <div
        style={{
          display: 'flex',
          width: '100%',

          gap: '5rem',
          marginTop: '8rem',
          marginBottom: '8rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: '#ffffff',
              fontSize: '36px',
              fontWeight: 'bold',
              marginBottom: '1rem',
              lineHeight: '54px',
            }}
          >
            Nossa Localização
          </Typography>
          <Maps />
        </div>
        <img src={LineVertical} alt='' height={'370vh'} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: '#ffffff',
              fontSize: '36px',
              fontWeight: 'bold',
              marginBottom: '1rem',
              lineHeight: '54px',
            }}
          >
            Links da Empresa
          </Typography>
          <Typography
            sx={{
              color: '#ffffff',
              fontSize: '18px',
              fontWeight: '600',
              marginBottom: '1rem',
              lineHeight: '27px',
              alignItems: 'center',
            }}
          >
            <ul
              style={{
                listStyle: 'unset',
                padding: '0',
                margin: '0',
              }}
            >
              {sections.map((section) => (
                <li key={section.index} style={{ marginBottom: '10px' }}>
                  <Link style={{ textDecoration: 'none', color: '#fff' }} href={section.id}>
                    {section.text}
                  </Link>
                </li>
              ))}
            </ul>
          </Typography>
        </div>
        <img src={LineVertical} alt='' height={'370vh'} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: '#ffffff',
              fontSize: '36px',
              fontWeight: 'bold',
              marginBottom: '1rem',
              lineHeight: '54px',
            }}
          >
            Contatos
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            <img
              src={WhatsApp}
              alt=''
              onClick={() => window.open('https://wa.me/message/QYMYNKLTSLQKK1', '_blank')}
              style={{ cursor: 'pointer' }}
            />
            <img src={Mail} alt='' onClick={() => sendFormContact()} style={{ cursor: 'pointer' }} />
          </div>
        </div>
        <img src={LineVertical} alt='' height={'370vh'} />
      </div>
    </FullPagePanel>
  );
};

export default Address;
