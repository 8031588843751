import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { Element } from 'react-scroll';
import Axios, { AxiosInstance } from 'axios';
import { Grid, Typography } from '@mui/material';

import { useStateIfMounted } from 'use-state-if-mounted';
import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';
import bg_white from '@assets/bg_white.svg';
import bgBlue from '@assets/bg_blue.svg';
import Frame10 from '@assets/Frame10.svg';
import Frame12 from '@assets/Frame12.svg';
import Frame13 from '@assets/Frame13.svg';
import Frame14 from '@assets/Frame14.svg';
import Frame4 from '@assets/Frame4.svg';
import Frame9 from '@assets/Frame9.svg';
import CardPlanFree from './components/CardPlanFree';
import CardPlanPaid from './components/CardPlanPaid';

// import logo from '@assets/logo.svg';

const plans_data = [
  {
    id: 1,
    description: 'Grátis',
    type_plan: 'free',
    is_active: true,
    annual_price: 0,
    monthly_price: 0,
    is_functions: true,
    storage_quota: 2,
    users_quota: 2,
    project_quota: 2,
    is_iot: false,
    is_ia: false,
  },
  {
    id: 2,
    description: 'Básico',
    type_plan: 'basic',
    is_active: true,
    annual_price: 3000,
    monthly_price: 250,
    is_functions: true,
    storage_quota: 5,
    users_quota: 10,
    project_quota: 5,
    is_iot: false,
    is_ia: false,
  },
  {
    id: 3,
    description: 'Pro',
    type_plan: 'pro',
    is_active: true,
    annual_price: 6000,
    monthly_price: 500,
    is_functions: true,
    storage_quota: 30,
    users_quota: 999,
    project_quota: 999,
    is_iot: true,
    is_ia: true,
  },
];
interface PlansRequest {
  data: any;
  total_items: number;
}
const axios: AxiosInstance = Axios.create({
  baseURL: 'http://proframe.com.br:3003',
});
const Plans = () => {
  const [plans, setPlans] = useStateIfMounted<any[]>(plans_data);

  const loadPlans = async () => {
    try {
      const res = await axios.get('/api/v1/plans', {
        params: {
          page: 1,
          per_page: 4,
          order: 'asc',
          order_by: 'id',
          search: '',
        },
      });

      const data = res.data;

      // console.log('data:', data);
      setPlans(data || plans_data);
      // setPlans(plans_data);
      // setTotalItems(total_items || 0);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setPlans([]);
      } else {
        console.log('Erro: ', err.message);
      }
    }
  };

  useEffect(() => {
    (async () => await loadPlans())();
  }, []);

  const setListResources = (plan: any) => {
    return [
      { description: 'Funções ilimitadas', done: plan.is_functions },
      { description: `Armazenamento de ${plan.storage_quota}GB`, done: true },
      { description: `Máximo de usuários: ${plan.users_quota}`, done: true },
      { description: `Máximo de obras: ${plan.project_quota}`, done: true },
      { description: 'Previsão do tempo in loco via IoT', done: plan.is_iot },
      // eslint-disable-next-line prettier/prettier
      { description: 'Identificação de EPI\'s via câmera com IA', done: plan.is_ia },
    ];
  };
  return (
    <FullPagePanel bgColor='red' background={`url(${bg_white})`}>
      <Grid container>
        <Grid item xs={12} paddingTop={3}>
          <Typography
            sx={{
              color: '#2F4A7E',
              fontSize: '8vh',
              fontWeight: '800',
              fontFamily: 'Montserrat',
              lineHeight: '137.06px',
              textAlign: 'center',
            }}
          >
            NOSSOS PLANOS
          </Typography>
        </Grid>
        <Grid
          container
          // xs={12}
          // gap={2}
          // bgcolor={'blue'}
          // display={'grid'}
          alignItems={'center'}
          justifyContent={'space-evenly'}
          justifyItems={'center'}
        >
          {plans.map((plan, index) =>
            plan.type_plan === 'free' ? (
              <Grid item xs={3} key={index}>
                <CardPlanFree
                  typePlan={plan.description}
                  title='Test Grátis'
                  subTitle={'Por 60 dias'}
                  // bodyText={plan.free.plan_description}
                  listDetails={setListResources(plan)}
                  onSelectPlan={(values) => null}
                />
              </Grid>
            ) : (
              <Grid item xs={3} key={index}>
                <CardPlanPaid
                  typePlan={plan.type_plan}
                  valueMonth={plan.monthly_price}
                  valueAnual={plan.annual_price}
                  subTitle={plan.description}
                  // bodyText={plan.free.plan_description}
                  listDetails={setListResources(plan)}
                  onSelectPlan={(values) => null}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
    </FullPagePanel>
  );
};

export default Plans;
