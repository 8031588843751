/* eslint-disable no-undef */
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import { Box, Button, Grid, Typography } from '@mui/material';

import slide_budget from '@assets/bg_budget.svg';
import slide_building from '@assets/bg_building.svg';
import slide_clients from '@assets/bg_clients.svg';
import slide_our_services from '@assets/bg_our_services.svg';
import slide_platform from '@assets/bg_platform.svg';
import icon_arrw_left from '@assets/icon_arrow_left.svg';
import icon_arrw_right from '@assets/icon_arrow_right.svg';
import { ReactComponent as IconBulletGrey } from '@assets/icon_bullet_grey.svg';
import { ReactComponent as IconBulletWhite } from '@assets/icon_bullet_white.svg';
import px2vw from '~/utils/px2vw';
import { isMobile, isTablet } from 'react-device-detect';

const Carrousel = ({ viewState, onSetSection, setViewState }) => {
  const navigate = useNavigate();
  const [oldSlide, setOldSlide] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  // const [activeSlide2, setActiveSlide2] = useState(0);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        // className={className}
        style={{
          ...style,
          display: 'block',
          cursor: 'pointer',
          background: 'transparent',
          position: 'absolute',
          // height: '10px',
          right: isMobile ? 5 : '60px',
          bottom: '45%',
        }}
        onClick={onClick}
      >
        <img src={icon_arrw_right} height={isMobile ? '40vh' : '50vh'} alt='icon arrow right' />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        // className={className}
        style={{
          ...style,
          display: 'block',
          cursor: 'pointer',
          background: 'transparent',
          position: 'absolute',
          bottom: '45%',
          left: isMobile ? 5 : '60px',
          zIndex: 9,
        }}
        onClick={onClick}
      >
        <img src={icon_arrw_left} height={isMobile ? '40vh' : '50vh'} alt='icon arrow left' />
      </div>
    );
  }
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 800,
    autoplaySpeed: 6000,
    autoplay: true,
    slidesToShow: 1, // Quantos slides mostrar de uma vez
    slidesToScroll: 1, // Quantos slides rolar de uma vez
    beforeChange: (current, next) => {
      setOldSlide(current);
      setActiveSlide(next);
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // afterChange: current => setActiveSlide2(current)
    appendDots: (dots) => (
      <div
        style={{
          // position: 'fixed',
          bottom: '20px',
          backgroundColor: 'transparent',
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        key={i}
        style={{
          width: '30px',
        }}
      >
        {i == activeSlide ? (
          <IconBulletWhite aria-label='icon bullet active' />
        ) : (
          <IconBulletGrey aria-label='icon bullet no sected' />
        )}
      </div>
    ),
  };

  const slider_body = [
    {
      id: 'Contact_8',
      background: slide_budget,
      title: 'SOLICITE SEU ORÇAMENTO',
      subTitle:
        'Execute sua obra conosco. Conte com uma equipe de gestão altamente qualificada e experiente. Cronogramas precisos e confiáveis para garantir o sucesso do seu projeto. Entre em contato e agende uma conversa para orçarmos personalizado.',
      buttonTxt: 'Solicite seu orçamento',
      handleClick: () => {
        navigate('/');
        // onSetSection(8);
      },
    },
    {
      background: slide_platform,
      title: 'NOSSA PLATAFORMA',
      subTitle:
        'Descubra a eficiência em cada etapa com a nossa plataforma de gerenciamento de obras. Simplificamos o processo, desde o planejamento até a conclusão, proporcionando uma gestão transparente, colaborativa e eficaz. Potencialize seus projetos conosco, a plataforma que eleva o gerenciamento de obras a um novo patamar.',
      buttonTxt: 'Acesse nossa plataforma',
      handleClick: () => {
        navigate('/platform');
      },
    },
    {
      id: 'Team_7',
      background: slide_building,
      title: 'CONSTRUINDO SUCESSO',
      subTitle:
        'Somos construtores de sonhos, transformando ideias em realidade com orgulho e alta qualidade desde nossa fundação.',
      buttonTxt: 'Saiba mais',
      handleClick: () => {
        navigate('/');
        // onSetSection(7);
      },
    },
    {
      id: 'Clients_4',
      background: slide_clients,
      title: 'CLIENTES E PARCERIAS',
      subTitle:
        'Nossos clientes nos inspiram a superar desafios e aprimorar nossa excelência em construção, enquanto parcerias sólidas nos ajudam a construir um futuro de sucesso e crescimento, alcançando resultados duradouros.',
      buttonTxt: 'Saiba mais',
      handleClick: () => {
        navigate('/');
        // onSetSection(4);
      },
    },
    {
      id: 'Services_2',
      background: slide_our_services,
      title: 'NOSSOS SERVIÇOS',
      subTitle:
        'Da concepção à conclusão, nossa construtora oferece serviços de excelência, garantindo projetos inovadores, qualidade impecável e prazos cumpridos. Conte conosco para construir o futuro que você imagina.',
      buttonTxt: 'Saiba mais',
      handleClick: () => {
        navigate('/');
        // onSetSection(2);
      },
    },
  ];
  return (
    <div
      className='carrousel_wrapper'
      style={{
        height: `${window.innerHeight}px`,
        // overflow: 'hidden',
        color: 'white',
        // width: '60%',
        // margin: 100,
      }}
    >
      <Slider {...settings}>
        {slider_body.map((item, index) => (
          <div key={index}>
            <Box
              key={index}
              className='carrousel_image'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                paddingX: '13%',
                backgroundImage: `url(${item.background})`,
                height: `${window.innerHeight}px`,
                // width: '60%',
              }}
            >
              <Grid container direction='row' spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    // component='h2'
                    // className='wrapper'
                    variant='h5'
                    sx={{ fontWeight: 800 }}
                    textAlign={'left'}
                    color={'white'}
                    fontFamily={'Montserrat'}
                    fontSize={isMobile ? px2vw(42) : px2vw(70)}
                  >
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} bgcolor={'transparent'}>
                  <Typography
                    sx={{ fontWeight: 500 }}
                    variant='caption'
                    color='white'
                    fontSize={isMobile ? px2vw(16) : px2vw(24)}
                    fontFamily={'Poppins'}
                    component='p'
                    textAlign={'left'}
                    // paddingX={'10%'}
                    // width={'80%'}
                  >
                    {item.subTitle}
                  </Typography>
                </Grid>
                <Grid item xs={12} bgcolor={'transparent'}>
                  <Button
                    variant='contained'
                    onClick={item.handleClick}
                    size='medium'
                    sx={{ marginTop: 1, bgcolor: '#DF7804' }}
                    href={`#${item.id}`}
                  >
                    {item.buttonTxt}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carrousel;
