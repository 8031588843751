import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { isMobile } from 'react-device-detect';
import { Element } from 'react-scroll';

import { Grid, Typography } from '@mui/material';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';
import bgBlue from '@assets/bg_blue.svg';
import Frame10 from '@assets/Frame10.svg';
import Frame12 from '@assets/Frame12.svg';
import Frame13 from '@assets/Frame13.svg';
import Frame14 from '@assets/Frame14.svg';
import Frame4 from '@assets/Frame4.svg';
import Frame9 from '@assets/card_IFC_web.svg';
import { ReactComponent as ImgTecArmazena } from '@assets/img_tec_armazena.svg';
import { ReactComponent as ImgTecIa } from '@assets/img_tec_ia.svg';
import { ReactComponent as ImgTecNuvem } from '@assets/img_tec_nuvem.svg';
import { ReactComponent as ImgTecOrcamento } from '@assets/card_IFC_mobile.svg';
import { ReactComponent as ImgTecParceria } from '@assets/img_tec_parceria.svg';
import { ReactComponent as ImgTecPrevisao } from '@assets/img_tec_previsao.svg';
import img_tec_armazena from '@assets/img_tec_armazena.svg';
import img_tec_ia from '@assets/img_tec_ia.svg';
import img_tec_nuvem from '@assets/img_tec_nuvem.svg';
import img_tec_orcamento from '@assets/img_tec_orcamento.svg';
import img_tec_parceria from '@assets/img_tec_parceria.svg';
import img_tec_previsao from '@assets/img_tec_previsao.svg';

import px2vw from '~/utils/px2vw';

// import logo from '@assets/logo.svg';

const Technologies = () => {
  return (
    <FullPagePanel bgColor='white' background={`url(${bgBlue})`}>
      <Grid container gap={2} paddingTop={isMobile ? 0 : '5%'}>
        <Grid
          item
          xs={12}
          alignItems={'center'}
          justifyContent={'center'}
          // bgcolor={'red'}
        >
          <Typography
            fontFamily={'Montserrat'}
            fontSize={isMobile ? px2vw(30) : px2vw(50)}
            fontWeight={800}
            // paddingBottom={5}
            color={'#fff'}
            textAlign={'center'}
          >
            NOSSAS TECNOLOGIAS
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          gap={isMobile ? 4 : 2}
          // bgcolor={'blue'}
          // display={'grid'}
          alignItems={'center'}
          justifyContent={'center'}
          justifyItems={'center'}
        >
          {!isMobile ? (
            <>
              <Grid item xs={12} bgcolor={'transparent'} display={'flex'} justifyContent={'center'} gap={3}>
                <img src={Frame4} alt='Frame4' width={'230vw'} />
                <img src={Frame9} alt='Frame9' width={'230vw'} />
                <img src={Frame10} alt='Frame10' width={'230vw'} />
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'} gap={3}>
                <img src={Frame12} alt='Frame12' width={'230vw'} />
                <img src={Frame13} alt='Frame13' width={'230vw'} />
                <img src={Frame14} alt='Frame14' width={'230vw'} />
              </Grid>
            </>
          ) : (
            <>
              {/* <img src={img_tec_armazena} alt='img_tec_armazena' width={'350vw'} /> */}
              <ImgTecArmazena height={'25vh'} />
              <ImgTecIa height={'25vh'} />
              <ImgTecNuvem height={'25vh'} />
              <ImgTecOrcamento height={'25vh'} />
              <ImgTecParceria height={'25vh'} />
              <ImgTecPrevisao height={'25vh'} />
              {/* <img src={img_tec_ia} alt='img_tec_ia' width={'350vw'} />
              <img src={img_tec_nuvem} alt='img_tec_nuvem' width={'350vw'} />
              <img src={img_tec_orcamento} alt='img_tec_orcamento' width={'350vw'} />
              <img src={img_tec_parceria} alt='img_tec_parceria' width={'350vw'} />
              <img src={img_tec_previsao} alt='img_tec_previsao' width={'350vw'} /> */}
            </>
          )}
        </Grid>
      </Grid>
    </FullPagePanel>
  );
};

export default Technologies;
