import { ReactText } from 'react';
import { toast, ToastOptions, UpdateOptions } from 'react-toastify';

interface ToastConfig {
  text: string;
  options?: ToastOptions;
}

interface ToastUpdateConfig {
  notificationId: ReactText;
  options?: UpdateOptions;
}

const defaultOptions = {
  autoClose: 2500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

/**
 * componente responsável pelas notificações do sistema
 *
 * @returns retorna funções para notificar (sucesso, informação, aviso, erro)
 */
export const Notify = {
  /**
   * função de mensagem de sucesso
   *
   * @param text - string responsavel por receber textos para as notificações
   * @param options - Object (autoClose,hideProgressBar,closeOnClick,pauseOnHover,draggable,progress) responsavel pela configurações de como o toast vai se comportar e aparecer para o sistema
   *
   *
   * @returns returna uma menssagem de sucesso
   */
  success: ({ text, options }: ToastConfig) => {
    toast.success(text, {
      ...defaultOptions,
      ...options,
    });
  },
  /**
   * função de mensagem de informação
   *
   * @param text - string responsavel por receber textos para as notificações
   * @param options - Object (autoClose,hideProgressBar,closeOnClick,pauseOnHover,draggable,progress) responsavel pela configurações de como o toast vai se comportar e aparecer para o sistema
   *
   *
   * @returns returna uma menssagem de informação
   */
  info: ({ text, options }: ToastConfig) => {
    toast.info(text, {
      ...defaultOptions,
      ...options,
    });
  },
  /**
   * função de mensagem de aviso
   *
   * @param text - string responsavel por receber textos para as notificações
   * @param options - Object (autoClose,hideProgressBar,closeOnClick,pauseOnHover,draggable,progress) responsavel pela configurações de como o toast vai se comportar e aparecer para o sistema
   *
   *
   * @returns returna uma menssagem de aviso
   */
  warning: ({ text, options }: ToastConfig) => {
    toast.warning(text, {
      ...defaultOptions,
      ...options,
    });
  },
  /**
   * função de mensagem de erro
   *
   * @param text - string responsavel por receber textos para as notificações
   * @param options - Object (autoClose,hideProgressBar,closeOnClick,pauseOnHover,draggable,progress) responsavel pela configurações de como o toast vai se comportar e aparecer para o sistema
   *
   *
   * @returns returna uma menssagem de erro
   */
  error: ({ text, options }: ToastConfig) => {
    toast.error(text, {
      ...defaultOptions,
      ...options,
    });
  },
  /**
   * função de mensagem de atualização
   *
   * @param notificationId - string responsavel por receber atualizações de textos para as notificações
   * @param options - Object (autoClose,hideProgressBar,closeOnClick,pauseOnHover,draggable,progress) responsavel pela configurações de como o toast vai se comportar e aparecer para o sistema
   *
   *
   * @returns returna uma menssagem de atualização
   */

  updateNotification: ({ notificationId, options }: ToastUpdateConfig) => {
    toast.update(notificationId, {
      render: 'Sucesso!',
      type: 'success',
      isLoading: false,
      ...defaultOptions,
      ...options,
    });
  },
};
