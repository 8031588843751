import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as IconMenuClose } from '@assets/icon_close_menu.svg';
import { ReactComponent as IconMenuHumburg } from '@assets/icon_menu_humburguer.svg';
import { ReactComponent as LogoSm } from '@assets/logo_sm_proframe.svg';

import px2vw from '~/utils/px2vw';

export const HeaderMenu = ({ onSetSection }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [itemMenuActive, setItemMenuActive] = React.useState<string>('');
  const handleNavigation = (sectionNumber, id) => {
    navigate('/');
    // onSetSection(sectionNumber);
    // setTimeout(() => {
    if (id !== 'Platform_3') {
      window.location.href = `#${id}`;
    } else {
      window.location.href = '#';
    }
    // }, 1000);
  };
  const sections = [
    {
      id: 'home_1',
      name: 'Home',
      action: () => {
        setAnchorElNav(null);
        handleNavigation(1, 'home_1');
      },
    },
    {
      id: 'Services_2',
      name: 'Serviços',
      action: () => {
        setAnchorElNav(null);
        handleNavigation(2, 'Services_2');
      },
    },
    {
      id: 'Platform_3',
      name: 'Plataforma',
      action: () => {
        setAnchorElNav(null);
        navigate('/platform/#');
      },
    },
    {
      id: 'Clients_4',
      name: 'Clientes',
      action: () => {
        setAnchorElNav(null);
        handleNavigation(4, 'Clients_4');
      },
    },
    {
      id: 'Partners_5',
      name: 'Parceiros',
      action: () => {
        setAnchorElNav(null);
        handleNavigation(5, 'Partners_5');
      },
    },
    {
      id: 'WhoWeAre_6',
      name: 'Quem somos',
      action: () => {
        setAnchorElNav(null);
        handleNavigation(6, 'WhoWeAre_6');
      },
    },
    {
      id: 'Team_7',
      name: 'Equipe',
      action: () => {
        setAnchorElNav(null);
        handleNavigation(7, 'Team_7');
      },
      // navigate('/#7');
    },
    {
      id: 'Contact_8',
      name: 'Contato',
      action: () => {
        setAnchorElNav(null);
        handleNavigation(8, 'Contact_8');
      },
    },
  ];
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position='fixed'
      style={{
        backgroundColor: '#fff',
        // transition: 'background-color 300ms ease',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'stretch', margin: 0 }}>
        <Toolbar
          disableGutters
          style={{
            backgroundColor: '#fff',
            margin: 0,
            // color: '#000',
            display: 'flex',
            flex: 1,
            justifyContent: 'stretch',
          }}
        >
          <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 5 }}>
            <LogoSm height={35} />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, ml: 1, flex: 1, justifyContent: 'center', marginY: 0 }}>
            <LogoSm height={35} />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='false'
              onClick={handleOpenNavMenu}
              color='inherit'
              sx={{ paddingY: 0, margin: 0 }}
            >
              {anchorElNav ? <IconMenuClose width={35} height={55} /> : <IconMenuHumburg width={35} height={55} />}
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                // mt: '6vh',
                '& .MuiButtonBase-root:hover': {
                  color: theme.palette.secondary.main,
                },
                '& .MuiTypography-root': {
                  color: theme.palette.primary.main,
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                  textTransform: 'none',
                },
                '& .MuiTypography-root:hover': {
                  color: theme.palette.secondary.main,
                },
              }}
            >
              {sections.map((section, index) => (
                <Button
                  key={index}
                  onClick={section.action}
                  href={section.id != 'Platform_3' ? `#${section.id}` : '#'}
                  fullWidth
                  sx={{ paddingY: 2 }}
                >
                  <Typography textAlign='center'>{section.name}</Typography>
                </Button>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, mx: 7, justifyContent: 'space-between' }}>
            {sections.map((section, index) => (
              <Button
                key={`web_${index}`}
                // href={`#${index}`}
                // onTouchStart={() => navigate('#7')}
                onClick={() => {
                  section.action();
                  setItemMenuActive(section.id);
                }}
                className='header_menu'
                sx={{
                  my: 2,
                  mx: 2,
                  display: 'block',
                  textTransform: 'none',
                  fontSize: px2vw(14),
                  color: itemMenuActive == section.id ? '#F3950D' : '#1F3867',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                }}
              >
                {section.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
