import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { Element } from 'react-scroll';

import { Grid, Typography } from '@mui/material';

import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';

// import bg_white from '@assets/bg_white.svg';
import bg_blue from '@assets/bg_img_blue.png';
import imgTeamAndreza from '@assets/team_andreza.svg';
import imgTeamRenan from '@assets/team_renan.svg';
import imgTeamSergio from '@assets/team_sergio.svg';
import { isMobile } from 'react-device-detect';
import px2vw from '~/utils/px2vw';
// import logo from '@assets/logo.svg';
const infoTeam = [
  {
    name: 'Renan Silva Arquiteto',
    photo: imgTeamRenan,
    resume:
      'Especialista em BIM e tem experiências com projetos residenciais e industriais. Já realizou projetos e obras para a Jabil, LG, Instituto Creathus, Instituto Conecthus, Luxpay e outros.',
  },
  {
    name: 'Andreza Fontes Eng. Civil',
    photo: imgTeamAndreza,
    resume:
      'Já acompanhou e geriu várias obras de reforma residencial e industrial, tembém possui grande experiência na área de Facilities. Já geriu obras na Transire, Instituto Conecthus e Callidus',
  },
  {
    name: 'Sérgio Gomes Arquiteto',
    photo: imgTeamSergio,
    resume:
      'Tem vasta experiência em construções, além de ser especialista em levantamento e análise topográfica. Já realizou obras para os Shoppings Ponta Negra, Grande Circular, Manauara e outros.',
  },
];

const Team = () => (
  <FullPagePanel bgColor='white' background={`url(${bg_blue})`}>
    <Grid
      container
      direction='row'
      spacing={5}
      sx={{
        paddingX: '5%',
      }}
    >
      <Grid item xs={12} marginLeft={isMobile ? 0 : '5%'} paddingBottom={isMobile ? 2 : 5}>
        <Typography
          variant='h1'
          textAlign={'left'}
          color={'white'}
          fontFamily={'Montserrat'}
          fontSize={isMobile ? px2vw(38) : px2vw(50)}
          fontWeight={800}
        >
          NOSSA EQUIPE
        </Typography>
      </Grid>
      {infoTeam.map((infoPerson, index) => (
        <Grid
          key={index}
          item
          xs={isMobile ? 12 : 4}
          // bgcolor={'green'}
          display={'flex'}
          // justifyContent={'left'}
          alignItems={'flex-start'}
          // paddingRight={isMobile ? 0 : 7}
        >
          <Grid item xs marginX={0} paddingX={2}>
            <img src={infoPerson.photo} alt='Descrição do imgTeamRenan' height={isMobile ? '250vh' : '370vh'} />
          </Grid>
          <Grid item xs paddingLeft={isMobile ? 3 : 0}>
            <Typography
              variant='caption'
              color='white'
              fontSize={isMobile ? px2vw(20) : '3.0vh'}
              fontWeight={900}
              fontFamily={'Poppins'}
              component='p'
              textAlign={'left'}
              // width={'80%'}
            >
              {infoPerson.name}
            </Typography>
            <Typography
              variant='caption'
              color='white'
              fontSize={isMobile ? px2vw(14) : '2vh'}
              fontWeight={500}
              fontFamily={'Poppins'}
              component='p'
              textAlign={'left'}
              // bgcolor={'red'}
              // width={'85%'}
            >
              {infoPerson.resume}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </FullPagePanel>
);

export default Team;
