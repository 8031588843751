import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { Element } from 'react-scroll';
import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';

import bg_white from '@assets/bg_white.svg';
import bg_blue from '@assets/bg_img_blue.png';
import { Grid, Typography } from '@mui/material';
import logoBig from '@assets/logo_lg_proframe.svg';
import { isMobile } from 'react-device-detect';
import px2vw from '~/utils/px2vw';

// import logo from '@assets/logo.svg';

const WhoWeAre = () => {
  return (
    <FullPagePanel bgColor='white' background={`url(${bg_white})`}>
      <Grid
        container
        direction='row'
        spacing={isMobile ? 5 : 10}
        sx={{
          paddingX: isMobile ? '5%' : '10%',
        }}
      >
        <Grid item xs={12}>
          <Typography
            // component='h5'
            // className='wrapper'
            variant='h1'
            textAlign={'left'}
            color={'#1F3867'}
            fontFamily={'Montserrat'}
            fontSize={isMobile ? px2vw(38) : px2vw(50)}
            fontWeight={800}
          >
            QUEM SOMOS
          </Typography>
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : 6}
          bgcolor={'transparent'}
          display={'flex'}
          justifyContent={isMobile ? 'center' : 'right'}
          alignItems={'center'}
        >
          <img src={logoBig} alt='Descrição do SVG1' width={isMobile ? '90%' : '65%'} />
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : 6}
          bgcolor={'transparent'}
          display={'flex'}
          justifyContent={isMobile ? 'center' : 'left'}
          alignItems={'center'}
        >
          <Typography
            variant='caption'
            color={'#1F3867'}
            fontSize={isMobile ? px2vw(17) : px2vw(24)}
            fontWeight={400}
            fontFamily={'Poppins'}
            lineHeight={2}
            component='p'
            textAlign={'left'}
            width={isMobile ? '90%' : '85%'}
          >
            Somos mais do que uma simples empresa de construção. Somos construtores de sonhos, dedicados a transformar
            ideias em realidade. Desde a nossa fundação, temos orgulho em oferecer serviços de construção de alta
            qualidade que atendem às necessidades únicas de cada cliente
          </Typography>
        </Grid>
      </Grid>
    </FullPagePanel>
  );
};

export default WhoWeAre;
