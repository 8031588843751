import { Controller } from 'react-hook-form';

import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

// import { PasswordStrength } from '@utils/PasswordStrength';

import { StrengthIndicator } from './styles';

/**
 * Componente de input de texto
 * @description Usado para facilitar o uso de input com validação da lib react-hook-form
 * @author Douglas Queiroz <douglas.queiroz@proframe.com.br>
 * @param name string - name usado no input para controle do formulário
 * @param control Object - instancia do formulario que está controlando o input
 * @param defaultValue string - valor inicial do input
 * @param rules Object - configurações de validação para o input usadas no react-hook-form
 * @param strengthIndicator boolean - indica se o campo terá a exibição de força de senha
 * @param rest Object - restante das propriedades para o input do material-ui
 * @returns ReactElement retorna um elemento HTML
 */

export const FormInputText = ({
  name,
  control,
  defaultValue = '',
  rules = { required: true },
  strengthIndicator = false,
  color = '',
  width = '',
  heigth = '',
  heightMult = null,

  // theme,
  ...rest
}) => {
  const onChange = rest['onChange'];
  delete rest['onChange'];
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules, required: !!rest['required'] }}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <>
            <TextField
              InputProps={{
                // endAdornment: <InputAdornment position='end'>CONTINUAR</InputAdornment>,
                style: {
                  fontFamily: 'poppins',
                  fontSize: '14px',
                  height: heightMult || 'auto',
                  alignItems: heightMult ? 'flex-start' : 'center',
                  backgroundColor: 'white',
                  borderRadius: 8,
                  borderWidth: 2,
                },
              }}
              ref={field.ref}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
              // InputLabelProps={{ style: { borderRadius: '8px' } }}
              // inputProps={{
              //   style: { fontFamily: 'poppins', fontSize: '14px', padding: 12 },
              // }}
              value={field.value || ''}
              {...rest}
              sx={{
                backgroundColor: color,
                width: width || '100%',
                height: '100%',
                // Estilos para a borda padrão
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#4167B0', // cor padrão da borda
                    borderRadius: 1.5,
                  },
                  // Estilos para o hover
                  '&:hover fieldset': {
                    borderColor: '#2F4A7D',
                    boxShadow: theme?.shadows[1] || 'none', // cor da borda em hover
                  },
                  // Estilos para o campo quando está focado
                  '&.Mui-focused fieldset': {
                    borderColor: '#2F4A7D', // cor da borda quando está focado
                  },
                },
              }}
            />
          </>
        );
      }}
    />
  );
};
