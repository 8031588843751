import { isMobile } from 'react-device-detect';

import { Box, Grid, Typography } from '@mui/material';

import { FullPagePanel } from '@components/fullpage_fs';

import bg_blue from '@assets/bg_img_blue.png';
import backgroundtwo from '@assets/images/Backgroundtwo.png';
import Frame5 from '@assets/images/Frame 5.png';
import Frame6 from '@assets/images/Frame 6.png';
import Frame7 from '@assets/images/Frame 7.png';
import Frame8 from '@assets/images/Frame 8.png';
import ScrenBudgets from '@assets/gestao.svg';

import px2vw from '~/utils/px2vw';

const Management = () => {
  return (
    <FullPagePanel background={`url(${bg_blue})`} bgColor='transparent'>
      <Grid container>
        <Grid
          item
          xs={isMobile ? 12 : 6}
          display={'flex'}
          flexDirection={'column'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          justifyContent={'center'}
          padding={isMobile ? '10%' : '5%'}
          // bgcolor={'red'}
        >
          <Typography
            fontFamily={'Montserrat'}
            fontSize={isMobile ? px2vw(30) : px2vw(50)}
            fontWeight={800}
            paddingBottom={5}
            color={'#fff'}
            textAlign={'left'}
          >
            GESTÃO DE OBRAS
          </Typography>
          <img
            src={ScrenBudgets}
            alt='Simple'
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : 6}
          direction={'column'}
          padding={2}
          // bgcolor={'red'}
          display={'flex'}
          alignItems={isMobile ? 'center' : 'start'}
          justifyContent={'center'}
        >
          <Box
            bgcolor={'transparent'}
            // width={isMobile ? '89%' : '90%'}
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'space-between'}
          >
            <img src={Frame5} alt='' width={'40%'} style={{ marginLeft: isMobile ? '5%' : 0 }} />
            <img src={Frame6} alt='' width={'40%'} style={{ marginLeft: isMobile ? '5%' : 0 }} />
            <img src={Frame7} alt='' width={'40%'} style={{ marginLeft: isMobile ? '5%' : 0 }} />
            <img src={Frame8} alt='' width={'40%'} style={{ marginLeft: isMobile ? '5%' : 0 }} />
          </Box>
          {!isMobile && (
            <Box width={'90%'} paddingTop={3}>
              <Typography
                variant='caption'
                color='#FFFFFF'
                fontSize={isMobile ? px2vw(16) : px2vw(20)}
                fontWeight={500}
                fontFamily={'Poppins'}
                component='span'
                textAlign={'left'}
                // width={'80%'}
              >
                <ul>
                  Maximize a eficiência com nosso gerenciamento de obras, projetada para simplificar cada aspecto do
                  processo construtivo:
                  <li>
                    <span style={{ fontWeight: 900 }}>Gerenciamento de Tarefas:</span> Organize todas suas atividades
                  </li>
                  <li>
                    <span style={{ fontWeight: 900 }}>Cronograma de Tarefas:</span> Mantenha-se no prazo com um
                    cronograma cronograma interativo.
                  </li>
                  <li>
                    <span style={{ fontWeight: 900 }}>Relatório Diário de Obra:</span> Registre detalhes importantes do
                    dia a dia da obra.
                  </li>
                  <li>
                    <span style={{ fontWeight: 900 }}>Armazenamento de Documentos:</span> Centralize todos os arquivos
                    essenciais de forma segura.
                  </li>
                </ul>
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </FullPagePanel>
  );
};

export default Management;
