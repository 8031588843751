import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { isMobile } from 'react-device-detect';
import { Element } from 'react-scroll';

// eslint-disable-next-line import-helpers/order-imports
import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPageContainer } from '@components/fullpage_fs';

import { Address } from '../platform/section';
import { Clients, Contact, Partners, Services, Service2, Team, WhoWeAre } from './section';

// import logo from '@assets/logo.svg';

const Home = ({ viewState, onSetSection, setViewState, windowHeight }) => {
  const sections = [
    {
      index: 1,
      id: 'home_1',
      component: <Slider viewState={viewState} onSetSection={onSetSection} setViewState={setViewState} />,
    },
    {
      index: 2,
      id: 'Services_2',
      component: <Services />,
    },
    {
      index: 3,
      id: 'Service2_3',
      component: <Service2 />,
    },
    {
      index: 4,
      id: 'Clients_4',
      component: <Clients />,
    },
    {
      index: 5,
      id: 'Partners_5',
      component: <Partners />,
    },
    {
      index: 6,
      id: 'WhoWeAre_6',
      component: <WhoWeAre />,
    },
    {
      index: 7,
      id: 'Team_7',
      component: <Team />,
    },
    {
      index: 8,
      id: 'Contact_8',
      component: <Contact />,
    },
    {
      index: 9,
      id: 'Address_9',
      component: !isMobile ? <Address onSetSection={onSetSection} /> : <></>,
    },
  ];
  return (
    <>
      <FullPageContainer
        showIndicators={true}
        viewState={viewState}
        onSetSection={onSetSection}
        setViewState={setViewState}
        windowHeight={windowHeight}
      >
        {sections.map((item) => (
          <section key={item.index} id={item.id}>
            {item.component}
          </section>
        ))}
        {/* <Address /> */}
      </FullPageContainer>
    </>
  );
};
export default Home;
