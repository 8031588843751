import React, { useState, useEffect, useRef } from 'react';
// import './App.css';
import { isMobile } from 'react-device-detect';
import { Element } from 'react-scroll';

import { Box, Button, Grid, Typography } from '@mui/material';

import Slider from '@components/carrousel';

// eslint-disable-next-line import-helpers/order-imports
import { FullPagePanel } from '@components/fullpage_fs';
import bgBlue from '@assets/bg_blue.svg';
import TextBuild from '@assets/TextBuild.png';
import timeBuild from '@assets/timeBuild.png';

import px2vw from '~/utils/px2vw';

// import logo from '@assets/logo.svg';

const TimeValuable = () => {
  return (
    <FullPagePanel bgColor='transparent' background={`url(${bgBlue})`}>
      <Grid container xs={12} padding={'8%'}>
        <div>
          <Typography
            fontFamily={'Montserrat'}
            fontSize={isMobile ? px2vw(28) : px2vw(50)}
            fontWeight={800}
            // paddingBottom={5}
            color={'#fff'}
            textAlign={'left'}
          >
            SEU TEMPO É VALIOSO
          </Typography>
          <div
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 800,
              fontSize: isMobile ? px2vw(30) : px2vw(60), // Tamanho da fonte
              color: '#080A21', // Cor do texto transparente
              // WebkitTextStroke: isMobile ? '1px white' : '2px white', // Sombra branca para simular a borda
              textShadow: '0 0 5px white, 0 0 3px white',
            }}
          >
            ASSIM COMO SUA CONSTRUÇÃO
          </div>
          {/* <img src={TextBuild} alt='assim como sua construcao' height={'60vh'} /> */}
        </div>
        <Box display={'flex'} flexDirection={isMobile ? 'column-reverse' : 'row'}>
          <Grid item xs={isMobile ? 12 : 6} marginTop={4} paddingLeft={isMobile ? '5%' : '0%'}>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: isMobile ? px2vw(16) : px2vw(18),
                fontWeight: '500',
                fontFamily: 'Poppins',
                lineHeight: '30.2px',
                textAlign: 'left',
              }}
            >
              Não deixe para depois o que nossa plataforma pode fazer por sua construção hoje! Acesse agora, experimente
              gratuitamente por 7 dias e descubra como simplificamos e aprimoramos cada aspecto do gerenciamento de
              obras. Seu caminho para o sucesso começa aqui. Não perca tempo, transforme agora sua visão em realidade.
            </Typography>
            <div
              style={{
                width: '140%',
                marginTop: '30px',
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#ff8c00',
                  width: isMobile ? '25vw' : '10vw',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#ff8c00',
                  },
                }}
                // onClick={() => window.open('http://www.proframe.com.br:3000/register')}
              >
                Em breve{/* Acesse a PROFRAME */}
              </Button>
            </div>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 6}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            paddingTop={2}
          >
            <img src={timeBuild} height={isMobile ? '220vh' : '330vh'} alt='time build' />
          </Grid>
        </Box>
      </Grid>
    </FullPagePanel>
  );
};

export default TimeValuable;
