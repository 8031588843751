import React from 'react';
import Carrousel from './Carrousel';
import TimeUntil from './TimeUntil';

const Slider = ({ viewState, onSetSection, setViewState }) => {
  return (
    <div style={{ position: 'relative', display: 'block' }}>
      <Carrousel viewState={viewState} onSetSection={onSetSection} setViewState={setViewState} />
      {/* <div className='place_name'>
        <div className='wrapper'>New Zealand</div>
      </div> */}
      {/* <TimeUntil /> */}
    </div>
  );
};

export default Slider;
